/* eslint-disable */

import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import Modal from "react-modal";
import "./App.css";
// import ProgressBar from "./components/ProgressBar.js"; // Import this at the beginning of your App.js file

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    textAlign: "center",
    backgroundColor: "antiquewhite",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)", // RGBA color for overlay. Adjust the last value to control the opacity (0.75 means 75% opaque)
    zIndex: 1000,
  },
};

function App() {
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stateName, setStateName] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [imgData, setImgData] = useState({});
  const [headline, setHeadline] = useState("");
  const [article, setArticle] = useState("");
  const [projection] = useState("geoAlbersUsa");
  // const [progressSeconds, setProgressSeconds] = useState(150); // initialize progress bar with the total seconds
  const [modalImage, setModalImage] = useState(null);
  const wsUrl = "wss://meowomatic.com:9090/ws";
  const ws = new WebSocket(wsUrl);

  useEffect(() => {
    // handle websocket messages
    const handleWebSocketMessage = (message) => {
      // setProgressSeconds((prevState) => (prevState ? null : 150));

      let response = message.data;
      const property_id = response.replaceAll(" ", "_");

      fetch(
        `https://worker-broad-silence-8406.patrickhoepner-cloudflare.workers.dev/get_image?id=thumb_${property_id}`
      )
        .then((res) => {
          if (!res.ok) {
            // res.ok is false if the HTTP response status is not in the 200-299 range
            throw new Error("Network response was not ok");
          }
          return res.text();
        })
        .then((data) => {
          setImgData((prevData) => ({
            ...prevData,
            [property_id]: `data:image/jpeg;base64,${data}`,
          }));
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    };

    // setup websocket
    ws.onopen = () => console.log("WebSocket client connected");
    ws.onmessage = (message) => handleWebSocketMessage(message);
    ws.onerror = (error) => console.log(`WebSocket error:`, error);
    ws.onclose = () => console.log("WebSocket connection closed");

    fetch(geoUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStateData(data.objects.states.geometries);
        data.objects.states.geometries.forEach((item) => {
          const property_id = item.properties.name.replaceAll(" ", "_");
          fetch(
            `https://worker-broad-silence-8406.patrickhoepner-cloudflare.workers.dev/get_image?id=thumb_${property_id}`
          )
            .then((res) => {
              if (!res.ok) {
                throw new Error("Network response was not ok");
              }
              return res.text();
            })
            .then((data) => {
              setImgData((prevData) => ({
                ...prevData,
                [property_id]: `data:image/jpeg;base64,${data}`,
              }));
            })
            .catch((error) => {
              console.error(
                "There has been a problem with your fetch operation:",
                error
              );
            });
        });
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation for geoUrl:",
          error
        );
      });
  }, []);

  const openModal = (name) => {
    const property_id = name.replaceAll(" ", "_");

    const fetchHeadline = fetch(
      `https://worker-broad-silence-8406.patrickhoepner-cloudflare.workers.dev/get_image?id=headline_${property_id}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.text();
      })
      .then((data) => {
        setHeadline(data);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });

    const fetchImage = fetch(
      `https://worker-broad-silence-8406.patrickhoepner-cloudflare.workers.dev/get_image?id=${property_id}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.text();
      })
      .then((data) => {
        setModalImage(`data:image/jpeg;base64,${data}`);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });

    const fetchArticle = fetch(
      `https://worker-broad-silence-8406.patrickhoepner-cloudflare.workers.dev/get_image?id=article_${property_id}`
    )
      .then((res) => {
        if (!res.ok) {
          setArticle("");
          throw new Error("Network response was not ok");
        }
        return res.text();
      })
      .then((data) => {
        setArticle(data);
      })
      .catch((error) => {
        setArticle("");
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });

    Promise.all([fetchHeadline, fetchImage, fetchArticle]).then(() => {
      setStateName(name);
      setModalIsOpen(true);
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="App">
      <header>
        <h1>Fear And Loathing 2024</h1> {/* Here is your page title */}
      </header>
      <div
        style={{
          width: "70%",
          height: "70%",
          marginLeft: "15%",
          cursor: "pointer",
        }}
      >
        <ComposableMap projection={projection}>
          <svg>
            <defs>
              {stateData.map((item, i) => {
                const property_id = item.properties.name.replaceAll(" ", "_");
                if (!imgData[property_id]) return null;
                return (
                  <pattern
                    key={i}
                    id={`${property_id}_Texture`}
                    patternUnits="objectBoundingBox"
                    patternContentUnits="objectBoundingBox"
                    x="0"
                    y="0"
                    width="1"
                    height="1"
                  >
                    <image
                      href={imgData[property_id]}
                      x="0"
                      y="0"
                      width="1"
                      height="1"
                      preserveAspectRatio="xMidYMid slice"
                    />
                  </pattern>
                );
              })}
            </defs>
          </svg>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={`url(#${geo.properties.name.replaceAll(
                    " ",
                    "_"
                  )}_Texture)`}
                  stroke="#C8BCAC"
                  strokeWidth={1.0}
                  onClick={() => openModal(geo.properties.name)}
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>
        </ComposableMap>
      </div>
      {/* <div className="portraits">
        <img src="assets/imgs/Trump.jpg" alt="Trump" id="trumpPortrait" />
        <img src="assets/imgs/Biden.jpg" alt="Biden" id="bidenPortrait" />
      </div> */}
      <div id="mainShareButtons">
        <FacebookShareButton
          url={window.location.href}
          hashtag="#FearAndLoathing2024"
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>

        <TwitterShareButton
          url={window.location.href}
          title={stateName && headline}
          via="YourTwitterHandle"
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="State Modal"
        style={customStyles}
      >
        <h2>{stateName}</h2>
        <p>{headline}</p>

        {stateName && (
          <img
            src={modalImage}
            alt="State"
            style={{ maxWidth: "650px", maxHeight: "650px" }} // set maximum width of image
          />
        )}
        <p id="articleText" dangerouslySetInnerHTML={{ __html: article }}></p>
        <button
          id="closeModalButton"
          onClick={closeModal}
          style={{
            position: "fixed",
            top: 0,
            right: 20,
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          X
        </button>
        <div id="modalShareButtons">
          <FacebookShareButton
            url={window.location.href}
            quote={stateName && headline}
            hashtag="#FearAndLoathing2024"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>

          <TwitterShareButton
            url={window.location.href}
            title={stateName && headline}
            hashtags={["FearAndLoathing2024"]}
            via="YourTwitterHandle"
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
      </Modal>
      {/* <ProgressBar seconds={progressSeconds} /> */}
    </div>
  );
}

export default App;
